import { Amplify } from "aws-amplify"
import { AWS_CONFIG } from "../../../aws-config"
import React, { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import QueryContent from "./QueryContent"
import { apiPostSelfAssessmentQuiz } from "api/queries"
import toastError from "utils/toastError"
import syncProfileWithSessionStorage from "components/QueryUserProfile/syncProfileWithSessionStorage"
import { UserType } from "api/types"
import HeroImage from "./HeroImage"
import Result from "./Result"
import View from "./View"
import { SelectedSelfAssessmentInput } from "api/graphql/API"

interface iSelfAssessmentPage {
  loggedInUser: UserType
}

const SelfAssessmentPage: React.FC<iSelfAssessmentPage> = ({
  loggedInUser,
}) => {
  if (!loggedInUser) return <p>Error</p>
  const [syncedQuestions, setSyncedQuestions] = useState<string[]>([])
  const [interimQuestions, setInterimQuestions] = useState<string[]>([])

  useEffect(() => {
    if (loggedInUser && loggedInUser.selectedSelfAssessment) {
      const parsedSelfAssessment = loggedInUser.selectedSelfAssessment.map(
        item => JSON.stringify(item)
      )

      setSyncedQuestions(parsedSelfAssessment)
      setInterimQuestions(parsedSelfAssessment)
    }
  }, [loggedInUser])

  useEffect(() => {
    Amplify.configure(AWS_CONFIG)
  }, [])

  const onSubmit = (data: { [id: string]: boolean[] }) => {
    const _filteredData = Object.keys(data)
      .map(item => {
        if (data[item] && data[item].length > 0) {
          return {
            id: item,
            selectedValue: data[item][0],
          } as SelectedSelfAssessmentInput
        }
      })
      .filter(Boolean)
    setInterimQuestions(_filteredData.map(item => JSON.stringify(item)))
    if (!loggedInUser || !_filteredData || !_filteredData.length) return
    apiPostSelfAssessmentQuiz(_filteredData as SelectedSelfAssessmentInput[])
      .then(data => {
        syncProfileWithSessionStorage({
          ...loggedInUser,
          ...(data as UserType),
        })
        if (data && data.selectedSelfAssessment) {
          setSyncedQuestions(
            data.selectedSelfAssessment.map(item => JSON.stringify(item))
          )
        }
      })
      .catch(err => {
        console.error(err)
        toastError(
          toast,
          "Something went wrong while syncing the data... Reload the page.",
          "bottom-left"
        )
      })
  }

  const getWaitingList = (A: (string | null)[], B: (string | null)[]) => {
    const waitList = A.filter(x => B.filter(y => x === y).length === 0).concat(
      B.filter(x => A.filter(y => x === y).length === 0)
    )
    const waitSet = new Set(
      waitList.map((item: string | null) => {
        if (!item) return null
        return Object.keys(JSON.parse(item))[0]
      })
    )
    return Array.from(waitSet)
  }

  return (
    <>
      <HeroImage />
      <QueryContent>
        {questions => (
          <>
            <View
              questions={questions}
              userProfile={loggedInUser}
              onSubmit={onSubmit}
              loadingList={getWaitingList(syncedQuestions, interimQuestions)}
            />
            <Result
              responsesWithQuestions={interimQuestions}
              totalNumberOfQuestions={questions.length}
            />
          </>
        )}
      </QueryContent>
      <ToastContainer />
    </>
  )
}

export default SelfAssessmentPage
