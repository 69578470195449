import React from "react"

import SiteContainer from "../SiteContainer"
import Form from "components/form/Form"
import { UserType } from "api/types"
import { ContentSeparator } from "../Utils/ContentSeparator"
import SelectInput from "./Inputs/SelectInput"
import { SelfAssessmentQuestionType } from "./types"

interface iSelfAssessmentView {
  questions: SelfAssessmentQuestionType[]
  userProfile: UserType
  onSubmit: (data: { [id: string]: boolean[] }) => void
  loadingList: (string | null)[]
}

const View: React.FC<iSelfAssessmentView> = ({
  questions,
  userProfile,
  onSubmit,
  loadingList,
}) => {
  let selfAssessmentInit = {} as { [id: string]: string[] }
  if (userProfile && userProfile.selectedSelfAssessment) {
    selfAssessmentInit = userProfile!.selectedSelfAssessment!.reduce(
      (prev, curr) =>
        Object.assign({}, prev, {
          [curr?.id as string]: [curr?.selectedValue],
        }),
      {}
    ) as { [id: string]: string[] }
  }
  const formDataInit = Object.assign(
    {},
    ...questions.map((item: SelfAssessmentQuestionType) => ({
      [item.id]: selfAssessmentInit[item.id] || [],
    }))
  )

  const onClickSelectButton = (item: any, id: any, value: any, data: any) => {
    if (item.select_limit === 1) {
      if (data[id] && data[id].includes(value)) return []
      return [value]
    }
    if (data[id].length < item.select_limit && !data[id].includes(value))
      return [...data[id], value]
    const newVals = data[id].filter((item: string) => item !== value)
    if (newVals.length !== data[id]) return newVals
  }
  return (
    <SiteContainer className="py-8">
      <section className="">
        <h1 className=" text-3xl text-site-blue-dark  md:text-4xl lg:text-5xl">
          Self assessment
        </h1>
        <ContentSeparator />
        <p className="text-site-blue-dark">
          Answer the following questions and we will provide you with guidance
          on next steps to take. Please be aware this assessment provides
          general information only and is not a substitute for medical care.
        </p>
        <ContentSeparator />
      </section>
      <ContentSeparator />
      <Form data={formDataInit} onSubmit={onSubmit} onChange={onSubmit}>
        {(data, setData) => (
          <div className="grid grid-cols-1 gap-6">
            {questions.map((item, index) => (
              <SelectInput
                key={index}
                show={item.type === "select"}
                loading={loadingList.includes(item.id)}
                id={item.id}
                text={item.text}
                options={item.options}
                onClick={(id, value) => {
                  if (loadingList.includes(item.id)) return
                  if (!item.select_limit) return
                  setData(id, onClickSelectButton(item, id, value, data))
                }}
                value={data[item.id]}
              />
            ))}
          </div>
        )}
      </Form>
    </SiteContainer>
  )
}

export default View
