import React from "react"
import { PuffLoader } from "react-spinners"

import OptionsList from "./OptionsList"

const DesktopView: React.FC<{
  id: string
  text: string
  options: any[]
  value?: string[] | null
  onClick: (question: string, value: string) => void
  loading: boolean
}> = ({ id, text, options, value, onClick, loading }) => {
  return (
    <>
      <div
        className={`hidden px-4 py-3 justify-between
                      bg-gray-100 border site-border-radius text-lg lg:flex
                      items-center`}
      >
        <div className="w-4/6 pr-4 text-site-blue-dark">{text}</div>
        <div className="w-2/6 flex items-center justify-end flex-wrap">
          {loading && (
            <div className="mr-4">
              <PuffLoader size={16} color={"#003b78"} loading={true} />
            </div>
          )}
          <OptionsList
            options={options}
            onClick={onClick}
            id={id}
            value={value}
          />
        </div>
      </div>
    </>
  )
}

export default DesktopView
