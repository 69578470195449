import { graphql, StaticQuery } from "gatsby"
import React from "react"

import { SelfAssessmentQuestionType } from "./types"

interface iQuerySelfAssessmentQuestions {
  children: (questions: SelfAssessmentQuestionType[]) => any
}

const QueryContent: React.FC<iQuerySelfAssessmentQuestions> = ({
  children,
}) => {
  const query = graphql`
    query {
      questions: allSelfAssessmentQuestionsYaml {
        nodes {
          id
          text
          type
          options {
            text
            value
          }
          select_limit
        }
      }
    }
  `

  return (
    <StaticQuery query={query}>
      {data => <>{children(data.questions.nodes)}</>}
    </StaticQuery>
  )
}

export default QueryContent
