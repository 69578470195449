import { Link } from "gatsby"
import React from "react"

import { FaArrowRight } from "react-icons/fa"
import { DiscoveryPage_InternalLink } from "utils/urls"
import SiteContainer from "../../SiteContainer"
import { LightBlueAppButton } from "../../buttons"
import { CardContentWrapper, CardWrapper } from "../../cards/base"
import { ContentSeparator } from "../../Utils/ContentSeparator"
import AdviceText from "./AdviceText"
import Images from "./Images"
import IncompleteFormError from "./IncompleteFormError"
import { IEVersion } from "utils"

/*
 * Here the "positive" means that the person may have
 * PCOS and "negative" means otherwise
 * */
type ResultStatusType = "positive" | "negative"

const Result: React.FC<{
  responsesWithQuestions: string[]
  totalNumberOfQuestions: number
}> = ({ responsesWithQuestions, totalNumberOfQuestions }) => {
  let isIE = false
  if (typeof window !== "undefined") {
    isIE = IEVersion() !== -1
  }
  if (responsesWithQuestions.length < totalNumberOfQuestions)
    return <IncompleteFormError />

  const onlyResponses = responsesWithQuestions.map(
    item => JSON.parse(item).selectedValue
  )
  const numberOf_No_Responses = onlyResponses.filter(resp => resp === false)
    .length
  let status: ResultStatusType = "negative"
  if (numberOf_No_Responses !== totalNumberOfQuestions) status = "positive"
  return (
    <SiteContainer className="pb-12 pt-4">
      <CardWrapper className="grid grid-cols-1 lg:grid-cols-2 hover:shadow-lg shadow-lg">
        <div style={{ width: isIE ? "50%" : "auto" }}>
          <Images status={status} />
        </div>
        <CardContentWrapper>
          <div className="h-full flex items-center py-2 lg:py-4 sm:px-4">
            <div>
              <h1 className="text-3xl text-site-blue-dark">Our advice</h1>
              <ContentSeparator />
              <AdviceText status={status} />
              {status === "positive" && (
                <div className="flex items-center justify-start mt-4">
                  <Link to={DiscoveryPage_InternalLink}>
                    <LightBlueAppButton
                      title="Explore topics"
                      Icon={<FaArrowRight />}
                      iconOnRight={true}
                    />
                  </Link>
                </div>
              )}
            </div>
          </div>
        </CardContentWrapper>
      </CardWrapper>
    </SiteContainer>
  )
}

export default Result
