import React from "react"

import DesktopView from "./DesktopView"
import MobileView from "./MobileView"

export interface iSelectInput {
  id: string
  show: boolean
  text: string
  options: any[]
  value?: string[] | null
  onClick: (question: string, value: string) => void
  loading: boolean
}

const SelectInput: React.FC<iSelectInput> = ({
  id,
  show,
  text,
  options,
  value,
  onClick,
  loading,
}) => {
  if (!show) return null
  return (
    <>
      <DesktopView
        id={id}
        text={text}
        options={options}
        onClick={onClick}
        loading={loading}
        value={value}
      />
      <MobileView
        id={id}
        text={text}
        options={options}
        onClick={onClick}
        loading={loading}
        value={value}
      />
    </>
  )
}

export default SelectInput
