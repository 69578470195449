import React from "react"

import { FaExclamationTriangle } from "react-icons/fa"
import SiteContainer from "../../SiteContainer"

const IncompleteFormError = () => {
  return (
    <SiteContainer className="flex items-center justify-center mb-12 mt-4">
      <p className="text-gray-500">
        <span className="mr-2">
          <FaExclamationTriangle className="inline" />
        </span>
        <span>Please, answer all questions</span>
      </p>
    </SiteContainer>
  )
}

export default IncompleteFormError
