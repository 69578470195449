import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

const HeroImage = () => {
  const query = graphql`
    {
      image: file(relativePath: { eq: "quiz.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `

  return (
    <StaticQuery query={query}>
      {data => (
        <Img
          fluid={data.image.childImageSharp.fluid}
          style={{ height: "256px" }}
        />
      )}
    </StaticQuery>
  )
}

export default HeroImage
