import React from "react"
import { PuffLoader } from "react-spinners"

import OptionsList from "./OptionsList"

const MobileView: React.FC<{
  id: string
  text: string
  options: string[] | null
  value?: string[] | null
  onClick: (question: string, value: string) => void
  loading: boolean
}> = ({ id, text, options, value, onClick, loading }) => {
  return (
    <div className="text-site-blue-dark bg-gray-100 border site-border-radius text-lg flex lg:hidden flex-col justify-between">
      <div className="px-4 py-3">
        <p className="text-site-blue-dark">{text}</p>
      </div>
      <div className="px-4 py-3 border-t flex items-center justify-end">
        <div className="flex items-center">
          {loading && (
            <div className="mr-4">
              <PuffLoader size={16} color={"#003b78"} loading={true} />
            </div>
          )}
          <OptionsList
            options={options}
            onClick={onClick}
            id={id}
            value={value}
          />
        </div>
      </div>
    </div>
  )
}

export default MobileView
