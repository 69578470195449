import React from "react"

import Layout from "components/layout"
import SelfAssessmentPage from "components/SelfAssessmentPage"
import SEO from "components/seo"

const SelfAssessment = () => {
  return (
    <Layout>
      <SEO title="Self assessment" />
      {/* @ts-ignore */}
      <SelfAssessmentPage />
    </Layout>
  )
}

export default SelfAssessment
