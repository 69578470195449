import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

const Images: React.FC<{ status: "positive" | "negative" }> = ({ status }) => {
  const query = graphql`
    {
      positive: file(
        relativePath: { eq: "self-assessment-positive-result.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      negative: file(
        relativePath: { eq: "self-assessment-negative-result.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `

  return (
    <StaticQuery query={query}>
      {data => (
        <>
          <Img
            className="lg:block hidden"
            fluid={data[status].childImageSharp.fluid}
            style={{
              borderBottomLeftRadius: "7px",
              borderTopLeftRadius: "7px",
              minHeight: "300px",
              height: "100%",
            }}
          />
          <Img
            className="block lg:hidden"
            fluid={data[status].childImageSharp.fluid}
            style={{
              borderTopRightRadius: "7px",
              borderTopLeftRadius: "7px",
              height: "256px",
            }}
          />
        </>
      )}
    </StaticQuery>
  )
}

export default Images
