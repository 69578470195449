import React from "react"

const AdviceText: React.FC<{ status: "positive" | "negative" }> = ({
  status,
}) => {
  if (status === "positive")
    return (
      <p className="text-site-blue-dark">
        Since you have responded YES to one or more of the questions, you may
        wish to speak with your doctor about an assessment for Early Menopause.
      </p>
    )
  return (
    <p className="text-site-blue-dark">
      As you have responded with a NO to all of the questions, it appears that
      you may not have this condition. However, if you have further questions or
      concerns we suggest you contact your doctor and discuss these with
      her/him.
    </p>
  )
}

export default AdviceText
