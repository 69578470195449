import React from "react"

import SelectButton from "./SelectButton"

const OptionsList: React.FC<{
  options: any[] | null
  value?: string[] | null
  onClick: (question: string, value: string) => void
  id: string
}> = ({ id, options, value, onClick }) => {
  if (!options) return null
  return (
    <>
      {options.map((item, index) => {
        const Element = (
          <SelectButton
            key={index}
            text={item.text}
            selected={
              value !== null && value !== undefined
                ? value.includes(item.value)
                : false
            }
            onClick={() => onClick(id, item.value)}
          />
        )
        if (index === options.length - 1) {
          return Element
        }
        return (
          <div className="mr-2" key={`div-${index}`}>
            {Element}
          </div>
        )
      })}
    </>
  )
}

export default OptionsList
