import React from "react"

import { FaCheck } from "react-icons/fa"
import { LightBlueAppButton, PinkAppButton } from "components/buttons"

const SelectButton: React.FC<{
  text: string
  selected: boolean
  onClick: () => void
}> = ({ text, selected, onClick }) => {
  if (selected) {
    return (
      <LightBlueAppButton
        type="button"
        style={{ paddingLeft: "26px", paddingRight: "26px" }}
        title={text}
        Icon={selected ? <FaCheck /> : undefined}
        onClick={onClick}
      />
    )
  }
  return (
    <PinkAppButton
      type="button"
      style={{ paddingLeft: "26px", paddingRight: "26px" }}
      title={text}
      Icon={selected ? <FaCheck /> : undefined}
      onClick={onClick}
    />
  )
}

export default SelectButton
